var render = function () {
  var _vm$statistics, _vm$statistics$data, _vm$statistics2, _vm$statistics2$data, _vm$statistics3, _vm$statistics3$data, _vm$statistics4, _vm$statistics4$data, _vm$statistics5, _vm$statistics5$data;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.statistics ? _c('b-card', [_c('b-card-text', [_c('p', {
    staticClass: "text-uppercase custom-text-disabled"
  }, [_vm._v(_vm._s(_vm.title))]), _vm.loading ? _c('div', {
    staticClass: "text-center"
  }, [_c('b-spinner', {
    staticClass: "mr-1",
    staticStyle: {
      "width": "3rem",
      "height": "3rem"
    },
    attrs: {
      "variant": "primary"
    }
  })], 1) : _c('b-list-group', {
    attrs: {
      "flush": ""
    }
  }, [_c('b-list-group-item', [_c('div', {
    staticClass: "text-base"
  }, [_vm._v(" Total Task: "), _c('span', {
    staticClass: "text-body-2 font-weight-bold"
  }, [_c('b-badge', {
    attrs: {
      "variant": "light-primary"
    }
  }, [_vm._v(_vm._s((_vm$statistics = _vm.statistics) === null || _vm$statistics === void 0 ? void 0 : (_vm$statistics$data = _vm$statistics.data) === null || _vm$statistics$data === void 0 ? void 0 : _vm$statistics$data.total_task_count))])], 1)])]), _c('b-list-group-item', [_c('div', {
    staticClass: "text-base font-weight-semibold"
  }, [_vm._v(" Total Word Count: "), _c('span', {
    staticClass: "text-body-2 font-weight-bold"
  }, [_c('b-badge', {
    attrs: {
      "variant": "light-info"
    }
  }, [_vm._v(_vm._s((_vm$statistics2 = _vm.statistics) === null || _vm$statistics2 === void 0 ? void 0 : (_vm$statistics2$data = _vm$statistics2.data) === null || _vm$statistics2$data === void 0 ? void 0 : _vm$statistics2$data.total_word_count) + " ")])], 1)])]), _c('b-list-group-item', [_c('div', {
    staticClass: "text-base font-weight-semibold"
  }, [_vm._v(" Total Complete Task: "), _c('span', {
    staticClass: "text-body-2 font-weight-bold"
  }, [_c('b-badge', {
    attrs: {
      "variant": "light-success"
    }
  }, [_vm._v(_vm._s((_vm$statistics3 = _vm.statistics) === null || _vm$statistics3 === void 0 ? void 0 : (_vm$statistics3$data = _vm$statistics3.data) === null || _vm$statistics3$data === void 0 ? void 0 : _vm$statistics3$data.total_complete_count) + " ")])], 1)])]), _c('b-list-group-item', [_c('div', {
    staticClass: "text-base font-weight-semibold"
  }, [_vm._v(" Total Incomplete Task: "), _c('span', {
    staticClass: "text-body-2 font-weight-bold"
  }, [_c('b-badge', {
    attrs: {
      "variant": "light-warning"
    }
  }, [_vm._v(_vm._s((_vm$statistics4 = _vm.statistics) === null || _vm$statistics4 === void 0 ? void 0 : (_vm$statistics4$data = _vm$statistics4.data) === null || _vm$statistics4$data === void 0 ? void 0 : _vm$statistics4$data.total_incomplete_count) + " ")])], 1)])]), _c('b-list-group-item', [_c('div', {
    staticClass: "text-base font-weight-semibold"
  }, [_vm._v(" Pending Due Task: "), _c('span', {
    staticClass: "text-body-2 font-weight-bold"
  }, [_c('b-badge', {
    attrs: {
      "variant": "light-danger"
    }
  }, [_vm._v(_vm._s((_vm$statistics5 = _vm.statistics) === null || _vm$statistics5 === void 0 ? void 0 : (_vm$statistics5$data = _vm$statistics5.data) === null || _vm$statistics5$data === void 0 ? void 0 : _vm$statistics5$data.total_pending_overdue_count) + " ")])], 1)])])], 1)], 1)], 1) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }